/*@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans&family=Exo:wght@700&display=swap');


font-family: 'Questrial', sans-serif;
*/
@font-face {
  font-family: 'Coolvetica';
  src: local('Coolvetica'), url(./assets/coolvetica.otf) format('opentype');
}
@font-face {
  font-family: 'Questrial';
  src: local('Questrial'), url(./assets/Questrial-Regular.ttf) format('truetype');
}

* {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

html, body {
  margin: 0;
  padding: 0;
  color: #0c0c0c;
  font-family: 'Questrial', sans-serif;
}

h1 {
  width: fit-content;
  margin: 0 auto;
  font-family: 'Coolvetica', sans-serif;
  padding: 0 1em 0 1em;
}

h2 {
  width: fit-content;
  margin: 0 auto;
  font-family: 'Coolvetica', sans-serif;
  padding: 1em;
}

h3 {
  width: fit-content;
  margin: 0 auto;
  font-family: 'Coolvetica', sans-serif;
  padding: 1em;
}

h4 {
  width: fit-content;
  font-family: 'Coolvetica', sans-serif;
  padding: 0;
  margin: 0 auto;
}

h5 {
  width: fit-content;
  font-family: 'Coolvetica', sans-serif;
  padding: 0;
  margin: 0 auto;
}

h6 {
  width: fit-content;
  margin: 0 auto;
  font-family: 'Coolvetica', sans-serif;
  padding: 1em;
}

p {
  line-height: 2em;
}

.underlineBlue {
  text-decoration: underline;
  -webkit-text-decoration-color: blue; /* Safari */  
  text-decoration-color: blue;
  text-decoration-thickness: 15%; 
}

.underlineRed {
  text-decoration: underline;
  -webkit-text-decoration-color: red; /* Safari */  
  text-decoration-color: red;
  text-decoration-thickness: 15%; 
}

.underlinePurple {
  text-decoration: underline;
  -webkit-text-decoration-color: purple; /* Safari */  
  text-decoration-color: purple;
  text-decoration-thickness: 15%; 
}

.underlineOrange {
  text-decoration: underline;
  -webkit-text-decoration-color: orange; /* Safari */  
  text-decoration-color: orange;
  text-decoration-thickness: 15%; 
}

.underlineGreen {
  text-decoration: underline;
  -webkit-text-decoration-color: green; /* Safari */  
  text-decoration-color: green;
  text-decoration-thickness: 15%; 
}

.underlineCyan {
  text-decoration: underline;
  -webkit-text-decoration-color: cyan; /* Safari */  
  text-decoration-color: cyan;
  text-decoration-thickness: 15%; 
}

#parallaxBanner {
  background-image: url("./assets/images/photo-react-background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100vw;
  position: relative;
  top: -90px;
}

#bannerText {
  background: #e7e7e7;
  color: black;
  display: inline-block;
  position: relative;
  transform: translate(-50%, -50%);
  top: 60%;
  left: 50%;
  padding: 0.4em 0 0.6em 0;
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 2.5em;
  }
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 1.3em;
  }
  h4 {
    font-size: 1.2em;
  }
  h5 {
    font-size: 1.3em;
  }
  h6 {
    font-size: 2.5em;
  }
  p {
    font-size: 1.2em;
  }
  #parallaxBanner {
    background-size: 200%;
    height: 25vh;
}
}

@media only screen and (min-width: 600px) {
  h1 {
    font-size: 3em;
  }
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 1.5em;
  }
  h4 {
    font-size: 1.2em;
  }
  h5 {
    font-size: 1.3em;
  }
  h6 {
    font-size: 2.5em;
  }
  p {
    font-size: 1.2em;
  }
  #parallaxBanner {
    height: 25vh;
  }
  @media only screen and (orientation: landscape) {
    #parallaxBanner {
        top: -60px;
    }

  }
}

@media only screen and (min-width: 992px) {
  @media only screen and (orientation: landscape) {
    #parallaxBanner {
        top: -90px;
    }
  }
  h1 {
    font-size: 4em;
  }
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 2em;
  }
  h4 {
    font-size: 1.5em;
  }
  h5 {
    font-size: 1.5em;
  }
  h6 {
    font-size: 2.5em;
  }
  p {
    font-size: 1.1em;
  }
  #parallaxBanner {
    height: 30vh;
}
}

:root {
  --backgroundInitial: 0;
  --backgroundStart: 0;
  --backgroundEnd: 0;
  --contentStart: 100vw;
  --contentMiddle: 0;
  --contentEnd: -100vw;
}

#wrapper {
  width: 100%;
  padding: 0;
  min-height: 100vh;
  background-color: #e6e6e6;
}

.content-enter {
  transform: translate(var(--contentStart), 0);
}

.content-enter-active {
  transform: translate(var(--contentMiddle), 0);
  transition: transform 300ms ease;
}

.content-exit {
  transform: translate(var(--contentMiddle), 0);
}

.content-exit-active {
  transform: translate(var(--contentEnd), 0);
  transition: transform 200ms ease;
}