#header {
    margin: 0 auto 3em auto;
    overflow: auto;
    width: 100%;
    text-align: center;
    position: sticky;
    z-index: 100;
    top: 0;
    background-color: #ffffff;
}

#headerLinks {
    position: relative;
    width: fit-content;
    margin: 0 auto;
    overflow: auto;
}
  
#header a, a:link, a:visited, a:hover, a:active {
    display: block;
    color: #0c0c0c;
    text-decoration: none;
}

#headerHome, #headerSkills, #headerProjects, #headerDemos {
    display: inline;
    float: left;
}

#header p {
    margin: 0.2em;
}

#underline {
    margin-top: 2em;
    position: relative;
    left: 0;
    height: 5px;
    width: 70px;
    transition-property: left;
    transition-duration: 0.5s;
    background-color: #000000;
}

#footer {
    width: 100vw;
    text-align: center;
    background-color: #ffffff;
    padding: 1em 0;
}

@media only screen and (max-width: 600px) {
    #header {
        padding-top: 2em;
        height: 10vh;
    }
    #headerHome, #headerSkills, #headerProjects, #headerDemos {
        margin: 0 4vw;
    }
    #header img {
        width: 18vw;
        height: auto;
    }
    #underline {
        margin-top: 1.5em;
        width: 50px;
        height: 5px;
    }
}

@media only screen and (min-width: 600px) {
    #header {
        padding-top: 2.5em;
        height: 110px;
    }
    #headerHome, #headerSkills, #headerProjects, #headerDemos {
        margin: 0 4vw;
    }
    #header img {
        width: 12vw;
        height: auto;
    }
    #underline {
        margin-top: 1.4em;
        width: 50px;
        height: 5px;
    }

    @media only screen and (orientation: landscape) {
        #header {
            padding-top: 1em;
            height: 15vh;
        }
    }
}

@media only screen and (min-width: 992px) {
    #header {
        padding: 2.5em 0 0 0;
        height: 120px;
        position: relative;
    }
    #headerHome, #headerSkills, #headerProjects, #headerDemos {
        margin: 0 2vw;
    }
    #header img {
        width: 120px;
        height: auto;
    }
    #underline {
        margin-top: 2em;
        width: 70px;
        height: 5px;
    }
}