#contentWrapper {
    margin: -90px auto 0 auto;
    height: fit-content;
    text-align: center;
}

#contentWrapper a, a:link, a:visited, a:hover, a:active {
    display: inline;
    color: #0000ee;
    text-decoration: underline;
}

#contentLeft {
    text-align: left;
}

#contentRight {
    text-align: left;
    overflow: visible;
}

.contentBox {
    width: 100%;
    overflow: auto;
    padding-top: 2em;
    text-align: left;
}

.contentBoxLower {
    width: 100%;
    overflow: auto;
    padding-top: 2em;
    text-align: left;
    margin-bottom: 0;
}

#homeWrapper {
    margin: -130px auto 0 auto;
    height: fit-content;
    text-align: center;
}

#homeWrapper a, a:link, a:visited, a:hover, a:active {
    display: inline;
    color: #0000ee;
    text-decoration: underline;
}

#landingBanner {
    background-image: url("../assets/images/photo-react-background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0 0;
    width: 100vw;
    position: relative;
    top: -150px;
    display: inline-block;
}

#landingText {
    background: #e7e7e7;
    color: black;
    display: inline-block;
    position: relative;
    transform: translate(-50%, -50%);
    top: 60%;
    left: 50%;
    padding: 0.4em 0 0.6em 0;
}


@media only screen and (max-width: 600px) {
    #contentWrapper {
        width: 100%;
        padding: 0 1em 1em 1em;
    }

    #homeWrapper {
        padding: 0 1em 1em 1em;
    }

    #landingBanner {
        background-size: 200%;
        height: 45vh;
    }

    .contentBox {
        padding-top: 2em;
    }

    .contentBoxLower {
        padding-top: 0;
    }

    #photoReact {
        width: 90vw;
    }

    #contentLeft {
        display: block;
        width: 100%;
    }

    #contentRight {
        display: block;
        width: 100%;
    }
    
    .focusesLeft {
        display: block;
        margin: 1em auto;
        width: 100%;
        text-align: left;
        overflow: auto;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 1em 1em 0 1em;
    }
    
    .focusesLeft img {
        width: 100px;
        height: auto;
        position: relative;
        top: 50%;
        margin-right: 1em;
    }
    
    .focusesRight {
        display: block;
        margin: 1em auto;
        width: 100%;
        text-align: left;
        overflow: auto;
        background-color: #ffffff;
        border-radius: 10px;
        padding: 1em 1em 0 1em;
    }
    
    .focusesRight img {
        width: 100px;
        height: auto;
        position: relative;
        top: 50%;
        margin-right: 1em;
    }
}
@media only screen and (min-width: 600px) {
    #contentWrapper {
        width: 100%;
        padding: 0 1em 1em 1em;
    }

    #contentLeft {
        display: block;
        width: 100%;
    }

    #homeWrapper {
        padding: 1em;
    }

    #landingBanner {
        height: 35vh;
    }

    #contentRight {
        display: block;
        width: 100%;
    }

    #photoReact {
        width: 60vw;
    }

    .contentBoxLower {
        padding-top: 0;
    }

    .focusBox {
        display: block;
    }

    .focusesLeft {
        display: block;
        margin: 0 auto;
        width: 80%;
        text-align: left;
        overflow: auto;
        background-color: #ffffff;
        border-radius: 20px;
        padding: 1em;
        margin-top: 2em;
    }
    
    .focusesLeft img {
        width: 100px;
        height: auto;
        position: relative;
        top: 50%;
        margin-right: 1em;
    }
    
    .focusesRight {
        display: block;
        margin: 0 auto;
        width: 80%;
        text-align: left;
        overflow: auto;
        background-color: #ffffff;
        border-radius: 20px;
        padding: 1em;
        margin-top: 2em;
    }
    
    .focusesRight img {
        width: 100px;
        height: auto;
        position: relative;
        top: 50%;
        margin-right: 1em;
    }

    @media only screen and (orientation: landscape) {
        #landingBanner {
            top: -75px;
        }
        #contentWrapper {
            margin-top: -50px;
        }
        #homeWrapper {
            margin-top: -90px;
        }
    }
}

@media only screen and (min-width: 992px) {
    #contentWrapper {
        width: 80%;
        min-width: 900px;
        padding: 0 1em 1em 1em;
    }

    #landingBanner {
        height: 60vh;
    }

    .contentBox {
        padding-top: 2em;
    }

    #contentLeft {
        float: left;
        display: inline;
        width: 50%;
    }

    #contentRight {
        float: right;
        width: 50%;
        padding-left: 2em;
    }

    #photoReact {
        width: 100%;
        height: auto;
    }

    #contentLeft {
        padding-right: 2em;
    }

    .topParagraph {
        margin-top: 0;
    }

    .focusBox {
        width: 100%;
        overflow: auto;
        padding-top: 2em;
        text-align: left;
        display: flex;
        justify-content: space-between;
    }

    .focusesLeft {
        width: 48%;
        text-align: left;
        overflow: auto;
        display: flex;
        padding: 1em;
        margin: 1em 0;
    }
    
    .focusesLeft img {
        width: 100px;
        height: auto;
        margin-right: 1em;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
    
    .focusesRight {
        width: 48%;
        text-align: left;
        overflow: auto;
        display: flex;
        padding: 1em;
        margin: 1em 0;
    }
    
    .focusesRight img {
        width: 100px;
        height: auto;
        margin-right: 1em;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }
}

@media only screen and (min-width: 1200px) {
    #homeWrapper {
        width: 60%;
        min-width: 1000px;
        padding: 0 1em 1em 1em;
    }

    #contentWrapper {
        width: 60%;
        min-width: 1000px;
        padding: 0 1em 1em 1em;
    }
    #contentRight {
        float: right;
        padding-left: 3em;
    }
    #photoReact {
        width: 100%;
        height: auto;
        float: right;
    }
}


@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
}
  
@keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
}
