@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

* {
    box-sizing: border-box;
}

.wrapper h1, .wrapper h2, .wrapper h3 {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    letter-spacing: -2px;
}

.wrapper p {
    font-size: 16px;
}

.wrapper h1 {
    font-size: 46px;
}

.wrapper h3 {
    font-size: 32px;
}

.wrapper {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    background-color: #ffffff;
}

table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
}

th {
    background-color: #e7e7e7;
    border: 1px solid #5c5c5c;
    padding: 1em;
}

tbody tr {
    border: 1px solid #000000;
}

td {
    border: 1px solid #5c5c5c;
    padding: 1em;
}

#sqlBox {
    margin: 0 auto;
    border-radius: 10px;
    background: #f0f0f0;
}

#sqlBox p {
    margin: 0;
}

#sqlView {
    background: #dfdfdf;
    border-radius: 10px;
    padding: 0.5em;
    cursor: pointer;
    color: #0000ff;
}

#sqlResults {
    padding: 1em;
    text-align: left;
    display: none;
}

#sqlResults table, #sqlResults tbody, #sqlResults th, #sqlResults tr, #sqlResults td {
    vertical-align: top;
    text-align: left;
    border: none;
    font-size: 12px;
    font-family: monospace;
    padding: 0 1em;
    line-height: 1.5;
}

.clickable {
    color: #0000ff;
    cursor: pointer;
}

.buttonHolder {
    margin: 2em auto 0 auto;
    width: fit-content;
    text-align: center;
}

.submitButton {
    margin: 0 1em 2em 1em;
    background-color: #c9c9c9;
    border-radius: 10px;
    padding: 0.5em 1em;
    display: inline-block;
    cursor: pointer;
    border: 1px solid #7e7e7e;
}

.buttonView {
    margin: 0 1em 2em 1em;
    background-color: #9595f5;
    border-radius: 10px;
    padding: 0.5em 1em;
    display: inline-block;
    cursor: pointer;
    border: 0 solid black;
}

.buttonView p {
    margin: 0;
}

select, input[type=text] {
    display: block;
    margin: 0 auto 1em auto;
}

input[type=submit] {
    margin: 0 auto;
    font-size: 16px;
}

#validationError {
    color: #ff0000;
    display: block;
    margin: 1em auto;
    padding: 0;
}

@media only screen and (max-width: 600px) {
    .columnDescription {
        display: none;
    }
    #sqlBox {
        width: 100%;
    }
    .sqlLeft {
        min-width: 9em;
    }
}

@media only screen and (min-width: 600px) {
    #sqlBox {
        width: 80%;
    }
    .sqlLeft {
        width: 20%;
        min-width: 9em;
    }
}

@media only screen and (min-width: 800px) {
    #sqlBox {
        width: 75%;
    }
    .sqlLeft {
        width: 25%;
        min-width: 9em;
    }
}

@media only screen and (min-width: 992px) {
    table {
        table-layout: fixed;
    }
    .columnTitle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 25%;
    }
    .columnDescription {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 50%;
    }
    .columnYear {
        width: 10%;
    }
    .columnRuntime {
        width: 15%;
    }
    /*
    #sqlBox {
        width: 75%;
    }
    .sqlLeft {
        width: 20%;
        min-width: 30em;
    }
    */
}

@media only screen and (min-width: 1200px) {
    /*
    #sqlBox {
        width: 65%;
    }
    .sqlLeft {
        min-width: 12em;
    }
    */
}