#projectWrapper {
    padding-bottom: 5em;
}

.projectDiv {
    display: flex;
    align-items: stretch;
    justify-content: center;
    background-color: #ffffff;
    padding: 1em;
    border-radius: 20px;
    margin: 0 auto;
    cursor: pointer;
    margin-top: 2em;
}

.projectDivLeft {
    display: flex;
    align-items: center;
    justify-content: center;
}

.projectDivRight {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    text-align: left;
    margin-left: 1em;
}

.projectDivRight h3 {
    margin: 0 auto;
    padding: 0;
}

.projectContent {
    overflow: auto;
    margin-top: 1em;
    text-align: left;
}

.projectContentImage {
    margin-top: 2em;
}

.projectContentLeft {
    float: left;
    display: inline;
    width: 50%;
}

.projectContentRight {
    float: right;
    display: inline;
    text-align: center;
}

#backButton {
    cursor: pointer;
    background-color: #829df7;
    padding: 0.1em 0.5em;
    border-radius: 20px;
}

.demoBox {
    margin: 3em auto 2em auto;
    background-color: #ffffff;
    padding: 1em;
    border-radius: 20px;
}

.demoBox h3 {
    padding: 0;
    margin: 0 auto;
}

.demoButtons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.demoButtons h4 {
    color: white;
    text-decoration: none;
}

.linkNoUnderline {
    text-decoration: none;
}

.startButton {
    margin: 0 auto;
    background-color: #3c3cc7;
    color: #ffffff;
    padding: 1em;
    border-radius: 10px;
    letter-spacing: 1px;
    cursor: pointer;
    width: fit-content;
}

.githubButton {
    margin: 0 auto;
    background-color: #b4b4b4;
    padding: 1em;
    border-radius: 10px;
    cursor: pointer;
    width: fit-content;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
}

#demoWrapper {
    padding: 1em;
    background-color: #ffffff;
    margin: -90px auto 0 auto;
    height: fit-content;
}

@media only screen and (max-width: 600px) {
    .projectDiv {
        flex-direction: column;
    }

    .projectDiv p {
        line-height: 1.5em;
    }

    .projectDivLeft img {
        width: 20%;
        height: auto;
    }

    .headerMobileOnly {
        display: block;
    }

    .headerDesktopOnly {
        display: none;
    }

    .projectDivLeft {
        display: flex;
        flex-direction: column;
    }

    .projectContentRight {
        width: 100%;
        margin: 1em 0 2em 0;
    }

    .mobileScreenPic {
        display: none;
    }

    .desktopScreenPic {
        width: 100%;
        height: auto;
    }

    #demoWrapper {
        width: 100%;
    }

    .demoBox {
        width: 100%;
    }

    .demoBox p {
        line-height: 1.5;
    }

    .demoBox .startButton, .demoBox .githubButton {
        margin: 0.5em auto;
    }

    .demoButtons {
        flex-direction: column;
    }
}
@media only screen and (min-width: 600px) {
    .projectDiv {
        flex-direction: row;
    }

    .projectDivLeft {
        width: 30%
    }

    .projectDivLeft img {
        height: 70%;
        width: auto;
        max-width: 150px;
    }

    .projectDivRight {
        width: 70%
    }

    .headerMobileOnly {
        display: none;
    }

    .headerDesktopOnly {
        display: block;
    }

    .projectContentRight {
        width: 50%;
    }

    .mobileScreenPic {
        width: 60%;
        height: auto;
    }

    .desktopScreenPic {
        display: none;
    }

    #demoWrapper {
        width: 100%;
        padding: 0 1em 1em 1em;
    }

    .demoBox {
        width: 100%;
    }

    .demoButtons {
        flex-direction: row;
    }
}

@media only screen and (min-width: 992px) {
    @media only screen and (orientation: landscape) {
        #landingBanner {
            top: -150px;
        }

        #contentWrapper {
            margin-top: -50px;
        }
    }

    .projectDivLeft {
        width: 30%
    }

    .projectDivLeft img {
        width: auto;
        height: auto;
        max-width: 100%;
    }

    .projectContent {
        margin-top: 0;
    }

    #demoWrapper {
        width: 80%;
        min-width: 900px;
        padding: 0 1em 1em 1em;
    }

    .demoBox {
        width: 75%;
    }
}

@media only screen and (min-width: 1200px) {
    .projectDivLeft {
        width: 20%
    }

    .projectDivRight {
        width: 80%
    }

    #demoWrapper {
        width: 60%;
        min-width: 1000px;
        padding: 0 1em 1em 1em;
    }

    .demoBox {
        width: 75%;
    }
}